import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { Intro } from '../../modules/Intro/Intro';


const DownloadOSXM1VIPPage = (): ReactElement => (
    useEffect(()=>{
        setTimeout(function(){
            window.location.replace('https://sketchwow2.s3.us-west-1.amazonaws.com/SketchWow-2.3.3-arm64.dmg.zip')
        }, 100)
        

        }, []),

  <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<>Thank you for downloading the latest Mac OSX M1/M2/M3 version.</>}
      
    />
  </Layout>
);

export default DownloadOSXM1VIPPage;
